import React,{useState , useContext , useEffect} from "react"
import { AuthContext } from "../../context/AuthContext/AuthContext"
import {useHistory , Link} from "react-router-dom"
import "./login.css"
const Login = () => {
    const history = useHistory()
const [username , setUsername] = useState("")
const [password , setPassword] = useState("")
     const {Login , error , isFetching , user } = useContext(AuthContext)
 const handleLogin = (e) =>{
     e.preventDefault()
     Login({username , password})
    //  if(!error && !isFetching ){
    //      history.push("/")
    //  }
    
     setUsername("")
     setPassword("")
 }
 useEffect(()=>{

      if(user){
         history.push("/")
      }

 },[isFetching , error , history , user])

     return (
         <div className="login">
              <div className="login_logo">
                
                  <img src="/LOGO REXIAM.png" alt="Logo" /> 
              </div>
              <form>
              <div className="flex">
                   <label>Username:</label>
               <input type="text" required className="loginInput" placeholder="username"value={username} onChange={(e)=>setUsername(e.target.value)} />
               </div>
               <div className="flex">
                      <label>Password:</label>
                      <input type="password" required className="loginInput"  placeholder="password" value={password} onChange={(e)=>setPassword(e.target.value)} / >
                  </div>
              
              
              <button className="button" type="submit" onClick={handleLogin} >Sign In</button>
              {
                  error && (
                      <div>
                      <span style={{color:"red"}}> Invalid Credential</span>
                      </div>
                  )
              }
              <div>
              <span style={{fontSize:"10"}}>Dont have an account? <Link to="/register">Register</Link> </span>
              </div>
              </form>
         </div>
        
     )
 }
 
 export default Login