import "./productList.css";
import { DataGrid } from '@mui/x-data-grid';
import {DeleteOutline} from "@material-ui/icons";
import {categoryRows} from "../../dummyData";
import { Link } from "react-router-dom";
import { useState , useContext  ,  useEffect } from "react";
import {CategoryContext} from "../../context/CategoryContext/CategoryContext";

export default function CategoryList() {
    const [data, setData] = useState(categoryRows);
    const {categories , deleteCategory } = useContext(CategoryContext);


 

//  console.log(categories)


    const handleDelete = (id) =>{
        setData(data.filter((item) => item.id !== id));
    }
    useEffect(()=>{
        const newData = categories.map((item , index)=>{
      

            const {_id,title,image} = item;
            return  {
              id:index+1, 
              title:title,
              image:image,
              props:_id
             
             
            }
           
            
          })
    
         setData(newData)
    },[categories])

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
         { field: 'product', headerName: 'Categories', width: 200, renderCell: (params) => {
            return(
                <div className="productListUser">
                    <img className="productListImg" src={params.row.image} alt="" />
                    {params.row.title}
                </div>
            )
        } },
        // { field: 'product', headerName: 'Product', width: 200 },
       
        // {
        //   field: 'price',
        //   headerName: 'Price',
        //   width: 190,
        // },
        // {
        //     field: 'stock',
        //     headerName: 'Stock',
        //     width: 190,
        //   },
        {
            field: 'action',
            headerName: 'Action',
            width: 190,
            renderCell: (params) =>{
                return(
                    <>
                    <Link to={"/category/" + params.row.props}>
                        <button className="productListEdit">Edit</button>
                    </Link>
                    <DeleteOutline className="productListDelete" onClick={()=>deleteCategory(params.row.props)}/>
                    </>
                 
                )
            }
          },
          {
            field: 'Products',
            headerName: 'Products',
            width: 190,
            renderCell: (params) =>{
                return(
                    <>
                    <Link to={"/C/" + params.row.props + "/" + params.row.title}>
                        <button className="productListEdit">Product</button>
                    </Link>
                    
                    </>
                 
                )
            }
          },
      ];
    return (
        <div className="productList">
             <div className="productTitleContainer" >
 <Link to="/newCategory">
               <button className="productAddButton">Create</button>
               </Link>
        </div>
          <DataGrid
        rows={data}
        columns={columns}
        disableSelectionOnClick
        pageSize={8}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
        </div>
    )
}
