import { createContext,  useReducer  , useEffect , useState , useContext} from "react";
import CategoryReducer from "./CategoryReducer";
import axios from "../../axios"
import {useHistory} from "react-router-dom";
import {AuthContext} from "../AuthContext/AuthContext"
// import axios from "axios"


const INTITIAL_START = {
  categories: [],
    isFetching: false,
    error: false
}

export const CategoryContext = createContext(INTITIAL_START);

export const CategoryContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(CategoryReducer, INTITIAL_START);
    // const fetchData = async() =>{
    //     const request = await axios.get(fetchUrl)
    //     setMovies(request.data.results)
        
    // }
    const {user} =  useContext(AuthContext)
    const history = useHistory();
    const [category , setCategory] = useState({})
    
  
 const createCategory = async (data) => {
         dispatch({type:"createCategoryStart"})
         try{
             const res = await axios.post("/category" , data , {
                headers:{
                    token: `Bearer ${user.accessToken}`
                },
             });
            
             dispatch({type:"createCategorySuccess", payload:res.data.data})
             console.log("success")
             }
             catch(error){
                 console.log(error)
               dispatch({type:"createCategoryFailure"})
             }
             
         }

     const deleteCategory = async(id) => {
         try{
             const res = await axios.delete(`/category/${id}`, {
                headers:{
                    token: `Bearer ${user.accessToken}`
                },  
             })
             console.log(res)
             dispatch({type:"deleteSuccess" , payload:id})
         }
         catch(error) {
             dispatch({type:"deleteFailure"})
         }
     }    
         
     const singleCategory = async(id)=>{
        try {
            const res = await axios.get(`/category/find/${id}`);
            const {data} = res.data
             if(data.length === 0){
               history.push("/")
             }
             // console.log(res.data)
             const {title , _id , image} = data 
             const newCat = {
                 name:title,
                 _id:_id,
                 image:image
             }
            //  console.log(newCat);
             setCategory(newCat)

            
         } catch (err) {
             console.log(err)
           
         }
     }
     const updateCategory = async(id , data)=>{
        console.log(data);
        try{
           const res = await axios.put(`/category/${id}` , data , {
              headers:{
                token: `Bearer ${user.accessToken}`
              },
           });
           dispatch({type:"updateCategory" , payload:{id,data}})
           // dispatch({type:"CreateProductSuccess", payload:res.data})
        //    history.push("/categories")
        //    alert("success")

           }
           catch(error){
               console.log(error)
           //   dispatch({type:"CreateProductFailure"})
           }
           
    }



    useEffect(() => {
        const getCategories = async () => {
            dispatch({type:"CategoryStart"});
            try {
               const res = await axios.get("/category");
                // console.log(res.data)
                const {data} = res.data
                // console.log(data)
                dispatch({type:"CategorySuccess", payload:data});
            } catch (err) {
                console.log(err)
                dispatch({type:"CategoryFailure" });
            }
        }
        getCategories()
    }, [])
    // console.log(state.categories)
    return <CategoryContext.Provider 
    value={{
        categories: state.categories, 
        isFetching: state.isFetching,
         error: state.error, category,
          dispatch ,  createCategory , deleteCategory 
          , singleCategory , updateCategory
        }}
    > 
        {children}
    </CategoryContext.Provider>
}