import "./product.css"
import {Link} from "react-router-dom";
import {CategoryContext} from "../../context/CategoryContext/CategoryContext";
import {useHistory, useParams} from "react-router-dom"
import { useState , useContext , useEffect } from "react";
import storage from "../../firebase"
import {Publish} from "@material-ui/icons"

export default function Category() {
  const {category , singleCategory , updateCategory} = useContext(CategoryContext)
  const {id} = useParams()
  const [title1 , setTitle1] = useState("")
  
  const [image1 , setImage1] = useState(null)
  const [progress , setProgress] = useState(0)
  const history = useHistory();
   
   useEffect(()=>{
     singleCategory(id)
   },[])

   const {_id , name , image} = category;

   const imageChange = (e) =>{
    if(e.target.files[0])
    {
        setImage1(e.target.files[0])
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   if(image1)
   {
     const uploadTask =  storage.ref(`images/${image1.name}`).put(image1)

     uploadTask.on(
         "state_changed",
         (snapshot)=>{
             // progress function
            const progress = Math.round(
                (snapshot.bytesTransferred/ snapshot.totalBytes) * 100
            );
            setProgress(progress)
         },
         (error)=>{ 
             // error function
             console.log(error)
             alert(error.message)
         },
         ()=>{
         //    complete fuction
           storage.ref("images").child(image1.name).getDownloadURL().then((image)=>{
             const data = 
             {
               title: title1 ? title1 : name,
               
               image:image

             }
               updateCategory(_id ,data)
              
              alert("success");
              history.push("/categories")
           })
           
          })
   }
   else{
     const data = 
     {
       title: title1 ? title1 : name,
       
       image:image

     }
       updateCategory(_id ,data);
       history.push("/categories")
   }
   
  }


    return (
        <div className="product">
            <div className="productTitleContainer">
              <h2 className="productTitle">Category</h2>
              <Link to="/newProduct">
               <button className="productAddButton">Create</button>
               </Link>
            </div>
            <div className="productTop">
              {/* <div className="productTopLeft">
               <Chart data={productData} dataKey="Sales" title="Sales Proformance"/>
              </div> */}
              <div className="productTopRight">
                <div className="productInfoTop">
                    <img src={image}
                     alt="" className="productInfoImg" />
                     <span className="productInfoName">{name}</span>
                </div>
                 <div className="productInfoBotton">
                    
                    {/* <div className="productInfoItems">
                      <span className="productInfoKey" >Sales:</span>
                      <span className="productInfoValue" >5123</span>
                    </div> */}
                    <div className="productInfoItems">
                      <span className="productInfoKey" >Active:</span>
                      <span className="productInfoValue" >yes</span>
                    </div>
                    {/* <div className="productInfoItems">
                      <span className="productInfoKey" >In Stock:</span>
                      <span className="productInfoValue" >no</span>
                    </div> */}
                 </div>
              </div>
            </div>
             <div className="productBottom">
                <form className="productForm" onSubmit={handleSubmit}>
                 <div className="productFormLeft">
                   <label>Product Name</label>
                   <input  value={title1} onChange={(e)=> setTitle1(e.target.value)} type="text"/>
                   
                    {/* <label>Active</label>
                    <select name="active" id="active">
                       <option value="yes">yes</option>
                       <option value="no">no</option>
                    </select> */}
                 </div>
                <div className="productFormRight">
                  <div className="productUpload">
                     <img src={image}
                     alt="" className="productUploadImg"/>
                     <label for="file">
                      <Publish/>
                     </label>
                     <input type="file"  onChange={imageChange} />
                  </div>
                  <progress style={{width:"50%"}} value={progress} max="100" />
                  <button className="productButton">Update</button>
                </div>
                </form>
             </div>
        </div>
    )
}
