import "./product.css"

// import Chart from "../../component/chart/Chart";
// import {productData} from "../../dummyData";
import {Publish} from "@material-ui/icons"
import storage from "../../firebase"
import {CategoryContext} from "../../context/CategoryContext/CategoryContext";
import {ProductContext} from "../../context/ProductContext/ProductContext";
import {useHistory, useParams , Link} from "react-router-dom"
import { useState , useContext , useEffect } from "react";
export default function Product() {
  const {singleProduct , product , updateProduct,error} = useContext(ProductContext)
  const {category , singleCategory , categories} = useContext(CategoryContext)
  const {id} = useParams();
  const history=useHistory();
  // const {title}  = category
  // const catTitle = title;
  const {_id , title , categoryID , desc , image} = product
  
  const [title1 , setTitle1] = useState("")
  const [desc1 , setDesc1] = useState("")
  const [image1 , setImage1] = useState(null)
  const [category1 , setCategory1] = useState("")


  useEffect(()=>{
   singleProduct(id);
  },[id , singleProduct])
  
  
  useEffect(()=>{
    singleCategory(categoryID)
  },[categoryID , singleCategory])
  // console.log(product);
  const [progress , setProgress] = useState(0)
 
  

  // console.log(categoryID)
 
   const imageChange = (e) =>{
     if(e.target.files[0])
     {
         setImage1(e.target.files[0])
     }
   };
 
   const handleSubmit = (e) => {
     e.preventDefault();
    if(image1)
    {
      const uploadTask =  storage.ref(`images/${image1.name}`).put(image1)
 
      uploadTask.on(
          "state_changed",
          (snapshot)=>{
              // progress function
             const progress = Math.round(
                 (snapshot.bytesTransferred/ snapshot.totalBytes) * 100
             );
             setProgress(progress)
          },
          (error)=>{ 
              // error function
              console.log(error)
              alert(error.message)
          },
          ()=>{
          //    complete fuction
            storage.ref("images").child(image1.name).getDownloadURL().then((image)=>{
              const data = 
              {
                title: title1 ? title1 : title,
                categoryID: category1 ? category1 : categoryID,
                desc: desc1 ? desc1 : desc,
                image:image

              }
                updateProduct(_id ,data)
                // console.log(image1)
              //  alert("success");
              if(!error){
                history.push("/products")
              }
            })
            
           })
    }
    else{
      const data = 
      {
        title: title1 ? title1 : title,
        categoryID: category1 ? category1 : categoryID,
        desc: desc1 ? desc1 : desc,
        image:image

      }
        updateProduct(_id ,data);
    }
    
 
   }
    return (
        <div className="product">
            <div className="productTitleContainer">
              <h2 className="productTitle">Product</h2>
              <Link to="/newProduct">
               <button className="productAddButton">Create</button>
               </Link>
            </div>
            <div className="productTop">
             
              <div className="productTopRight">
                <div className="productInfoTop">
                    <img src={image}
                     alt="" className="productInfoImg" />
                     <span className="productInfoName">{title}</span>
                </div>
                 <div className="productInfoBottom">
                    <div className="productInfoItems">
                      <span className="productInfoKey" >Category:</span>
                      <span className="productInfoValue" >{category.name}</span>
                    </div>
                   
                    <div className="productInfoItems">
                      <span className="productInfoKey" >Description:</span>
                      {/* <p className="productInfoValue" >mpofjuysvjhshidgvogsdaicywgsachdhvgcyvidagkyfsvygeasivgkovaefrfebagadb</p> */}

                    </div>
                    <p className="productDesc">{desc}</p>
                 </div>
              </div>
            </div>
             <div className="productBottom">
                <form className="productForm" onSubmit={handleSubmit}>
                 <div className="productFormLeft">
                   <label>Product Name</label>
                   <input  value={title1} onChange={(e)=> setTitle1(e.target.value)} type="text"/>
                    <label>Category</label>
                    <select  onChange={(e)=>setCategory1(e.target.value)}>
                      {categories.map((item )=>(<option key={item._id} value={item._id}>{item.title}</option>))}
                       {/* <option value="yes">yes</option>
                       <option value="no">no</option> */}
                    </select>
                    <label>Description</label>
                   <textarea value={desc1} onChange={(e)=>setDesc1(e.target.value)}  name="Description"  />
                 </div>
                <div className="productFormRight">
                  <div className="productUpload">
                     <img src={image} 
                     alt="" className="productUploadImg"/>
                     <label for="file">
                      <Publish/>
                     </label>
                     <input type="file"   onChange={imageChange} />
                  </div>
                  <progress style={{width:"50%"}} value={progress} max="100" />
                  <button className="productBotton" type="submit" >Update</button>
                </div>
                </form>
             </div>
        </div>
    )
}
