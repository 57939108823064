import React, { useEffect, useContext } from "react"
import Topbar from "./component/topbar/Topbar";
import Sidebar from "./component/sidebar/Sidebar";
import UserList from "./pages/userList/UserList";
import User from "./pages/user/User";
import NewUser from "./pages/newUser/NewUser";
import ProductList from "./pages/productList/ProductList";
import Product from "./pages/product/Product";
import NewProduct from "./pages/newProduct/NewProduct";
import Home from "./pages/home/Home"
import Category from "./pages/Category/Category"
import NewCategory from "./pages/newCategory/NewCategory"
import CategoryList from "./pages/categoryList/CategoryList"
import FullProducts from "./pages/fullProducts/fullProducts";
import Contact from "./pages/Contact/Contact";
import "./App.css";
import { Switch, Route, useHistory } from "react-router-dom"
import Login from "./component/Login/Login"
import Register from "./component/Login/Register"
import { AuthContext } from "./context/AuthContext/AuthContext";
// import {LogOut} from "./context/AuthContext/AuthContext"
import {useLocation} from  "react-router-dom";
import axios from "./axios";








function App() {
  const history = useHistory()
  const { user, isFetching, LogOut } = useContext(AuthContext)
  // const pathname = useLocation();


//   useEffect(()=>{
//     const createEvent = async() =>{
           
//       let something;
//  try{
//      const res =  await axios.post(`/category` , something , {
//         headers:{
//            token: `Bearer ${user?.accessToken}`
//          },
//       });
//     //   console.log(res.status)
      

//       }
//      catch(error){
//           // console.log(error.message)
//          if(error.message === "Request failed with status code 403"  ){
//            LogOut();
         
       
//          }
         
//      }
   
//      }

// createEvent()

//   },[    user?.accessToken  , LogOut ])




  useEffect(() => {
    if (!user && !isFetching) {
   
      history.push("/login")
    }
  }, [user, isFetching, ])





  // if(!user){
  //   history.push("/")
  //   return <Login/>
  // }
  const Layout = ({ children }) => {
    return (
      <>
        <Topbar />
        <div className="container">
          <Sidebar />
          {children}
        </div>
      </>
    )
  }
  return (



    <>

      <Switch>
        {/* <Route exact path="/">
          <Layout>
            <Home />
          </Layout>
        </Route> */}
        {/* <Route path="/users">
          <Layout>
            <UserList />
          </Layout>
        </Route>
        <Route path="/user/:userId">
          <Layout>
            <User />
          </Layout>
        </Route>
        <Route path="/newUser">
          <Layout>
            <NewUser />
          </Layout>
        </Route> */}
        <Route path="/products">
          <Layout>
            <FullProducts />
          </Layout>
        </Route>
        <Route path="/C/:id/:name">
          <Layout>
            <ProductList />
          </Layout>
        </Route>
        <Route path="/product/:id">
          <Layout>
            <Product />
          </Layout>
        </Route>
        <Route path="/newProduct">
          <Layout>
            <NewProduct />
          </Layout>
        </Route>
        <Route exact path="/">
          <Layout>
            <CategoryList />
          </Layout>
        </Route>
        <Route path="/newCategory">
          <Layout>
            <NewCategory />
          </Layout>
        </Route>
        <Route path="/contacts">
          <Layout>
            <Contact />
          </Layout>
        </Route>

        <Route path="/category/:id">
          <Layout>
            <Category />
          </Layout>
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
      </Switch>


    </>

  );
}

export default App;
