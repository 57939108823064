
export const userData = [
    {
      name: 'Jan',
      "Active User": 4000,
      
    },
    {
        name: 'Feb',
        "Active User": 3000,
        
    },
    {
        name: 'Mar',
        "Active User": 5000,
        
    },
    {
        name: 'Apr',
        "Active User": 4000,
        
    },
    {
        name: 'May',
        "Active User": 3000,
        
    },
    {
        name: 'Jun',
        "Active User": 4000,
        
    },
    {
        name: 'Jul',
        "Active User": 2000,
        
    },
    {
        name: 'Aug',
        "Active User": 3000,
        
    },
    {
        name: 'Sep',
        "Active User": 4000,
        
    },
    {
        name: 'Oct',
        "Active User": 1000,
        
    },
    {
        name: 'Nov',
        "Active User": 4000,
        
    },
    {
        name: 'Dec',
        "Active User": 3000,
        
    },
  
    
  ];


  export const productData = [
    {
      name: 'Jan',
      "Sales": 4000,
      
    },
    {
        name: 'Feb',
        "Sales": 3000,
        
    },
    {
        name: 'Mar',
        "Sales": 5000,
        
    }
    
  ];





 export const userRows = [
    { id: 1, userName: 'Jon Snow', avater: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?size=626&ext=jpg', email: "ebuka124@gmail.com", status: "active", transaction: "$122.000" },
    { id: 2, userName: 'Jon Snow', avater: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?size=626&ext=jpg', email: "ebuka124@gmail.com", status: "active", transaction: "$122.000" },
    { id: 3, userName: 'Jon Snow', avater: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?size=626&ext=jpg', email: "ebuka124@gmail.com", status: "active", transaction: "$122.000" },
    { id: 4, userName: 'Jon Snow', avater: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?size=626&ext=jpg', email: "ebuka124@gmail.com", status: "active", transaction: "$122.000" },
    { id: 5, userName: 'Jon Snow', avater: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?size=626&ext=jpg', email: "ebuka124@gmail.com", status: "active", transaction: "$122.000" },
    { id: 6, userName: 'Jon Snow', avater: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?size=626&ext=jpg', email: "ebuka124@gmail.com", status: "active", transaction: "$122.000" },
    { id: 7, userName: 'Jon Snow', avater: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?size=626&ext=jpg', email: "ebuka124@gmail.com", status: "active", transaction: "$122.000" },
    { id: 8, userName: 'Jon Snow', avater: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?size=626&ext=jpg', email: "ebuka124@gmail.com", status: "active", transaction: "$122.000" },
    { id: 9, userName: 'Jon Snow', avater: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?size=626&ext=jpg', email: "ebuka124@gmail.com", status: "active", transaction: "$122.000" },
    { id: 10, userName: 'Jon Snow', avater: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?size=626&ext=jpg', email: "ebuka124@gmail.com", status: "active", transaction: "$122.000" },
    
    
  ];


  export const productRows = [
    { id: 1, name: 'apple airpode', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "actives", price: "$122.000" },
    { id: 2, name: 'apple airpode', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    { id: 3, name: 'apple airpode', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "actives", price: "$122.000" },
    { id: 4, name: 'apple airpode', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    { id: 5, name: 'apple airpode', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "actives", price: "$122.000" },
    { id: 6, name: 'apple airpode', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "actives", price: "$122.000" },
    { id: 7, name: 'apple airpode', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    { id: 8, name: 'apple airpode', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    { id: 9, name: 'apple airpode', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    { id: 10, name: 'apple airpode', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    
    
  ];
  export const categoryRows = [
    { id: 1, name: 'Category', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "actives", price: "$122.000" },
    { id: 2, name: 'Category', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    { id: 3, name: 'Category', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "actives", price: "$122.000" },
    { id: 4, name: 'Category', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    { id: 5, name: 'Category', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "actives", price: "$122.000" },
    { id: 6, name: 'Category', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "actives", price: "$122.000" },
    { id: 7, name: 'Category', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    { id: 8, name: 'Category', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    { id: 9, name: 'Category', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    { id: 10, name: 'Category', img: 'https://images.unsplash.com/photo-1610438235354-a6ae5528385c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwbGUlMjBhaXJwb2RzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80', stock: 123, category: "active", price: "$122.000" },
    
    
  ];


